var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || !_vm.documentDataToAdd.parent
    }
  }, [_c('b-card', {
    attrs: {
      "id": "document-add"
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', [_c('b-card', {
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.infoDocs')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "py-0 px-1"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.docsName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "documentName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.docsName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "documentName",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('document.ph.docsName')
                },
                model: {
                  value: _vm.documentDataToAdd.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.documentDataToAdd, "name", $$v);
                  },
                  expression: "documentDataToAdd.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.Folder'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "documentFolder"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.Folder')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "options": _vm.folderList,
                  "reduce": function reduce(val) {
                    return val.id;
                  },
                  "clearable": false,
                  "label": "id",
                  "searchable": "",
                  "disabled": "",
                  "filterable": false,
                  "loading": _vm.loading,
                  "placeholder": _vm.$t('document.ph.folder')
                },
                on: {
                  "open": _vm.openFolder,
                  "search": _vm.searchFolder
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.name) + " ")])];
                  }
                }, {
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.name) + " ")])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref2) {
                    var loading = _ref2.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" Loading... ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.documentDataToAdd.parent,
                  callback: function callback($$v) {
                    _vm.$set(_vm.documentDataToAdd, "parent", $$v);
                  },
                  expression: "documentDataToAdd.parent"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.description'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "description"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.description')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('quill-editor', {
                ref: "quillRef",
                attrs: {
                  "options": _vm.editorOption
                },
                model: {
                  value: _vm.documentDataToAdd.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.documentDataToAdd, "description", $$v);
                  },
                  expression: "documentDataToAdd.description"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "File",
            "rules": "size:".concat(_vm.MAX_SIZE_TO_UPLOAD_FILE)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.file')) + " "), _vm.MAX_SIZE_TO_UPLOAD_FILE ? _c('em', [_vm._v("(Dung lượng tối đa: " + _vm._s(_vm.sizeFormatter(_vm.MAX_SIZE_TO_UPLOAD_FILE)) + ")")]) : _vm._e()])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('div', _vm._l(_vm.fileDocuments, function (fileDocument, indexDocument) {
                return _c('div', {
                  key: indexDocument,
                  staticClass: "font-weight-bold d-flex-center justify-content-start"
                }, [_vm._v(" " + _vm._s(indexDocument + 1) + ". " + _vm._s(fileDocument.name) + " (" + _vm._s(_vm.sizeFormatter(fileDocument.size)) + ") "), _c('b-button', {
                  staticClass: "p-50 ml-25",
                  attrs: {
                    "variant": "flat-danger"
                  },
                  on: {
                    "click": _vm.handleRemoveFileAdded
                  }
                }, [_c('feather-icon', {
                  attrs: {
                    "icon": "XIcon",
                    "size": "14"
                  }
                })], 1)], 1);
              }), 0), _c('div', {
                staticClass: "d-flex mt-25"
              }, [_c('b-form-file', {
                staticClass: "py-0",
                staticStyle: {
                  "z-index": "0"
                },
                attrs: {
                  "id": "file",
                  "multiple": "",
                  "accept": "*",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.newFileToAdd,
                  callback: function callback($$v) {
                    _vm.newFileToAdd = $$v;
                  },
                  expression: "newFileToAdd"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.docsStatus')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "px-1 py-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', [_c('b-form-group', {
          attrs: {
            "label-for": "status"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.isShowTitle')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "status",
            "switch": ""
          },
          model: {
            value: _vm.documentDataToAdd.isShow,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToAdd, "isShow", $$v);
            },
            expression: "documentDataToAdd.isShow"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.documentDataToAdd.isShow ? _vm.$t('document.forF3') : _vm.$t('document.forF2')))])], 1)])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', [_c('b-form-group', {
          attrs: {
            "label-for": "isActive"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.isActiveTitle')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "isActive",
            "switch": ""
          },
          model: {
            value: _vm.documentDataToAdd.isActive,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToAdd, "isActive", $$v);
            },
            expression: "documentDataToAdd.isActive"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.documentDataToAdd.isActive ? _vm.$t('document.publish') : _vm.$t('document.draft')))])], 1)])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', [_c('b-form-group', {
          attrs: {
            "label-for": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.isPinTitle')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "isPin",
            "switch": ""
          },
          model: {
            value: _vm.documentDataToAdd.isPin,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToAdd, "isPin", $$v);
            },
            expression: "documentDataToAdd.isPin"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('document.isPinTitle')))])], 1)])], 1)], 1), _vm.documentDataToAdd.isPin ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.priorityPin.title')
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "priorityPinTitle"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.priorityPin.title')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "priorityPinTitle",
            "options": _vm.PRIORITY_PIN,
            "reduce": function reduce(val) {
              return val.key;
            },
            "clearable": false,
            "label": "key",
            "loading": _vm.loading,
            "placeholder": _vm.$t('document.ph.folder')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "font-weight-bold d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t("document.priorityPin.".concat(data.key))) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "font-weight-bold d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t("document.priorityPin.".concat(data.key))) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.documentDataToAdd.priorityPin,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToAdd, "priorityPin", $$v);
            },
            expression: "documentDataToAdd.priorityPin"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(_vm.$te("document.priorityPin.desc".concat(_vm.documentDataToAdd.priorityPin)) ? _vm.$t("document.priorityPin.desc".concat(_vm.documentDataToAdd.priorityPin)) : '') + " ")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          staticClass: "d-flex-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          staticClass: "mx-50 px-3",
          attrs: {
            "variant": "outline-danger",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          staticClass: "mx-50 px-3",
          attrs: {
            "variant": "gradient",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": _vm.loading
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.submit);
            }
          }
        }, [_vm.loading ? _c('div', [_c('b-spinner', {
          attrs: {
            "small": ""
          }
        }), _vm._v(" " + _vm._s(_vm.$t('saving')) + " ")], 1) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")])])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }